html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: "Comfortaa", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: white;
  font-size: 115%;
}

* {
	box-sizing: border-box;
	max-width: 100%;

}

p {
	margin: 0.5rem;
}

a {
	color: inherit;
	text-decoration: none;
}

li {
	list-style-type: none;
}

:root {
	--panel-bgcolor: rgba(0,0,0,0.63);
	--hover: #fff;
	--button: rgba(163, 8, 8, 0.64);
}
html, body, #root {
	position: relative;
	z-index: -1;
 }

